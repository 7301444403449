import { create } from 'zustand';
import useUserStore from './userStore';

const useMessageStore = create((set) => ({
  messages: [
    {
      id: 1,
          text: `Hi, I'm here to help you build critical thinking skills. Do you want me to evaluate the example argument diagram? Or would you rather replace it with your own diagram, then let me evaluate? When you're ready, just press the **Evaluate** button below. If you want, I can instead provide a new argument (a short paragraph) for you to diagram. In that case, write "give me a different argument to diagram" in chat and press **Send**.`,
      type: 'bot',
      timestamp: new Date().toISOString()
    },

  ],
  addMessage: (message, type = 'user') => set((state) => ({
    messages: [...state.messages, {
      id: Date.now(),
      text: type === 'evaluation' ? message.trim() : message,
      type,
      timestamp: new Date().toISOString(),
      username: useUserStore.getState().user
    }]
  })),
  clearMessages: () => set({
    messages: [{
      id: Date.now(),
      text: "How can I help you today?",
      type: 'bot',
      timestamp: new Date().toISOString()
    }]
  })
}));

export default useMessageStore;