// App.jsx
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import './index.css';
import './styles/ArgumentMap.css';
import './styles/ChatBox.css';
import './styles/CustomNodes.css';
import './styles/Login.css';
import './styles/Legal.css';
import ArgumentMap from "./ArgumentMap";
import ChatBox from "./ChatBox";
import DevSettings from "./DevSettings";
import Login from "./components/Login";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import About from "./components/About";
import LoadingScreen from "./components/LoadingScreen";
import useDevStore from './stores/devStore';
import useUserStore from './stores/userStore';
import { useState, useEffect } from 'react';

function MainApp({ isMobile, activeView, setActiveView }) {
  const toggleDevSettings = useDevStore(state => state.toggleOpen);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === 'd') {
        e.preventDefault();
        toggleDevSettings();
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [toggleDevSettings]);

  if (isMobile) {
    return (
      <div className="mobile-container">
        <div className={`view-container ${activeView}`}>
          <div className="map-view">
            <ArgumentMap />
            <button 
              className="tab-button right"
              onClick={() => setActiveView('chat')}
            >
              Chat
            </button>
          </div>
          <div className="chat-view">
            <button 
              className="tab-button left"
              onClick={() => setActiveView('map')}
            >
              Map
            </button>
            <ChatBox />
          </div>
        </div>
        <DevSettings />
      </div>
    );
  }

  return (
    <div style={{ 
      width: '100%', 
      height: '100%', 
      position: 'fixed',
      top: 0,
      left: 0,
      overflow: 'hidden'
    }}>
      <Allotment defaultSizes={[65, 35]}>
        <Allotment.Pane minSize={400}>
          <div style={{ width: '100%', height: '100%' }}>
            <ArgumentMap />
          </div>
        </Allotment.Pane>
        <Allotment.Pane minSize={300}>
          <div style={{ width: '100%', height: '100%' }}>
            <ChatBox />
          </div>
        </Allotment.Pane>
      </Allotment>
      <DevSettings />
    </div>
  );
}

export function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [activeView, setActiveView] = useState('map');
  const { isAuthenticated, checkAuth } = useUserStore();
  const [isLoading, setIsLoading] = useState(true);

  // Initialize authentication
  useEffect(() => {
    const initAuth = async () => {
      await checkAuth();
      setIsLoading(false);
    };
    initAuth();
  }, [checkAuth]);

  // Mobile check
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Router>
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <MainApp 
                isMobile={isMobile}
                activeView={activeView}
                setActiveView={setActiveView}
              />
            ) : (
              <Login />
            )
          }
        />
      </Routes>
    </Router>
  );
}