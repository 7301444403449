import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Handle, Position, useUpdateNodeInternals } from '@xyflow/react';
import useStore from './stores/argumentStore';

// Utility function to get an alphabet letter based on index
const getAlphabetLetter = (index) => String.fromCharCode(97 + index);

// Custom Node Component
const CustomNode = ({ id, data, draggable }) => {
  const updateNodeHandles = useStore((state) => state.updateNodeHandles);
  const updateNodeData = useStore((state) => state.updateNodeData);
  const edges = useStore((state) => state.edges);
  const updateNodeInternals = useUpdateNodeInternals();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [hoveredHandle, setHoveredHandle] = useState(null);

  // Ensure data has default values to prevent undefined errors
  const nodeData = data || { handles: [], value: '' };
  const { handles = [], value = '' } = nodeData;

  // Separate handles by position
  const topHandles = handles.filter((h) => h.position === Position.Top);
  const bottomHandles = handles.filter((h) => h.position === Position.Bottom);

  const isInitialMount = useRef(true);
  const prevHandles = useRef(handles);

  // Effect to update node internals when handles change
  useEffect(() => {
    if (JSON.stringify(prevHandles.current) !== JSON.stringify(handles)) {
      prevHandles.current = handles;
      updateNodeInternals(id);
    }
  }, [handles, id, updateNodeInternals]);

  // Check if a handle has connections
  const hasConnections = useCallback(
    (handleId) => {
      return edges.some(
        (e) =>
          (e.source === id && e.sourceHandle === handleId) ||
          (e.target === id && e.targetHandle === handleId)
      );
    },
    [edges, id]
  );

  // Update handle types based on connected edges
  const updateHandleTypes = useCallback(
    (handles) =>
      handles.map((handle) => ({
        ...handle,
        type:
          edges.filter(
            (e) =>
              (e.target === id && e.targetHandle === handle.id) ||
              (e.source === id && e.sourceHandle === handle.id)
          ).length > 1
            ? 'dependent'
            : 'independent',
      })),
    [edges, id]
  );

  useEffect(() => {
    const updatedHandles = updateHandleTypes(handles);
    if (JSON.stringify(updatedHandles) !== JSON.stringify(handles)) {
      updateNodeHandles(id, updatedHandles);
      updateNodeInternals(id);
    }
  }, [edges, handles, id, updateHandleTypes, updateNodeHandles, updateNodeInternals]);

  // Handle text change
  const onChange = useCallback(
    (evt) => {
      updateNodeData(id, { value: evt.target.value });
    },
    [id, updateNodeData]
  );

  // Add a new handle
  const addHandle = useCallback(
    (position) => {
      const prefix = position === Position.Top ? 'top-' : 'bottom-';
      const existingHandles = handles.filter((h) => h.position === position);
      const newHandle = {
        id: `${prefix}${getAlphabetLetter(existingHandles.length)}`,
        type: 'independent',
        position,
      };
      updateNodeHandles(id, [...handles, newHandle]);
      updateNodeInternals(id);
    },
    [handles, id, updateNodeHandles, updateNodeInternals]
  );

  // Remove a handle
  const removeHandle = useCallback(
    (position) => {
      const existingHandles = handles.filter((h) => h.position === position);
      if (existingHandles.length > 1) {
        const updatedHandles = handles.filter(
          (h) => h.id !== existingHandles[existingHandles.length - 1].id
        );
        updateNodeHandles(id, updatedHandles);
        updateNodeInternals(id);
      }
    },
    [handles, id, updateNodeHandles, updateNodeInternals]
  );

  // Handle mouse events for tooltips
  const handleMouseEnter = (e, handle) => {
    if (handle.position === Position.Bottom && hasConnections(handle.id)) {
      const rect = e.target.getBoundingClientRect();
      setTooltipPosition({
        x: rect.left + window.scrollX,
        y: rect.bottom + window.scrollY + 5,
      });
      setTooltipVisible(true);
    }
    setHoveredHandle(handle.id);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
    setHoveredHandle(null);
  };

  // Render handles for a given position
  const renderHandles = (handles, position) => {
    return handles.map((handle, index) => {
      const isConnected = hasConnections(handle.id);
      const isBottom = position === Position.Bottom;
      const handleClass = `react-flow__handle ${
        handle.type === 'dependent' ? 'dependent' : ''
      } ${isBottom && isConnected ? 'disabled' : ''}`;

      return (
        <div
          key={handle.id}
          style={{
            position: 'absolute',
            [position === Position.Top ? 'top' : 'bottom']: '-10px',
            left: `${((index + 1) * 100) / (handles.length + 1)}%`,
            transform: 'translate(-50%, 0)',
          }}
        >
          <Handle
            type={position === Position.Top ? 'target' : 'source'}
            position={position}
            id={handle.id}
            className={handleClass}
            style={{
              position: 'relative',
              left: '0',
              transform: 'none',
            }}
            onMouseEnter={(e) => handleMouseEnter(e, handle)}
            onMouseLeave={handleMouseLeave}
          />
          {index === handles.length - 1 && (
            <button
              className={`handle-button add ${hoveredHandle === handle.id ? 'highlight' : ''}`}
              style={{
                [position === Position.Top ? 'top' : 'bottom']: '-10px',
                left: '20px',
              }}
              onClick={() => addHandle(position)}
            >
              +
            </button>
          )}
          {handles.length > 1 && index === handles.length - 1 && (
            <button
              className="handle-button remove"
              style={{
                [position === Position.Top ? 'top' : 'bottom']: '-10px',
                left: '35px',
              }}
              onClick={() => removeHandle(position)}
            >
              –
            </button>
          )}
        </div>
      );
    });
  };

  // Tooltip component
  const tooltip = tooltipVisible && (
    <div
      className="handle-tooltip visible"
      style={{
        left: tooltipPosition.x,
        top: tooltipPosition.y,
      }}
    >
      Use the + button to add another connection point
    </div>
  );

  return (
    <>
      <div className="custom-node">
        {renderHandles(topHandles, Position.Top)}
        <textarea
          value={value}
          onChange={onChange}
          className="nodrag"
          rows={5}
          placeholder="Enter your text here..."
        />
        {renderHandles(bottomHandles, Position.Bottom)}
      </div>
      {tooltip}
    </>
  );
};

const nodeTypes = {
  customNode: CustomNode,
};

export default nodeTypes;