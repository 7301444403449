import React, { useEffect, useRef, useMemo, useCallback, useState } from 'react';
import {
 ReactFlow,
 ReactFlowProvider,
 Background,
 Controls,
 useReactFlow,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import './styles/ArgumentMap.css';
import nodeTypes from './CustomNodes';
import useArgumentStore from './stores/argumentStore';
import useMessageStore from './stores/messageStore';
import DevSettings from './DevSettings';
import ArgumentMenu from './ArgumentMenu';
import EvaluateButtons from './components/EvaluateButtons';
import { layoutNodes } from './utils/graphUtils';
import { MessageCircleOff, RouteOff, Trash2, Plus } from 'lucide-react';

const ToggleSwitch = React.memo(({ isDeductive, onChange }) => {
 return (
   <div className="toggle-switch" onClick={onChange}>
     <div className={`switch-handle ${isDeductive ? 'deductive' : 'non-deductive'}`}>
       {isDeductive ? '✓' : '%'}
     </div>
   </div>
 );
});

const ClearButton = React.memo(({ type, initialIcon: InitialIcon, confirmIcon: ConfirmIcon, onClear, disabled, 'data-tooltip': tooltip }) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const timeoutRef = useRef(null);

  const handleClick = () => {
    if (isConfirming) {
      onClear();
      setIsConfirming(false);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    } else {
      setIsConfirming(true);
      timeoutRef.current = setTimeout(() => {
        setIsConfirming(false);
      }, 2000);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const Icon = isConfirming ? ConfirmIcon : InitialIcon;

  return (
    <button
      className={`clear-button ${type}-clear ${isConfirming ? 'confirming' : ''}`}
      onClick={handleClick}
      disabled={disabled}
      data-tooltip={tooltip}
    >
      <Icon size={24} />
    </button>
  );
});

const ArgumentMapContent = React.memo(() => {
 const nodes = useArgumentStore((state) => state.nodes);
 const edges = useArgumentStore((state) => state.edges);
 const isDeductive = useArgumentStore((state) => state.isDeductive);
 const isLoading = useArgumentStore((state) => state.isLoading);
 const addNewNode = useArgumentStore((state) => state.addNewNode);
 const onNodesChange = useArgumentStore((state) => state.onNodesChange);
 const onEdgesChange = useArgumentStore((state) => state.onEdgesChange);
 const onConnect = useArgumentStore((state) => state.onConnect);
 const setIsDeductive = useArgumentStore((state) => state.setIsDeductive);
 const handleEvaluate = useArgumentStore((state) => state.handleEvaluate);

 const reactFlowWrapper = useRef(null);
 const initialLoadComplete = useRef(false);
 const { fitView } = useReactFlow();

 const memoizedNodeTypes = useMemo(() => nodeTypes, []);

 useEffect(() => {
   if (nodes.length > 0 && !initialLoadComplete.current) {
     setTimeout(() => {
       fitView({ padding: 0.2 });
       initialLoadComplete.current = true;
     }, 100);
   }
 }, [nodes.length, fitView]);

 const handleEvaluateWithFitView = useCallback(() => {
   handleEvaluate();
   setTimeout(() => {
     fitView({ padding: 0.2 });
   }, 50);
 }, [handleEvaluate, fitView]);

 const handleUntangle = useCallback(() => {
   const { positionChanges } = layoutNodes(nodes, edges);
   onNodesChange(positionChanges);
   setTimeout(() => {
     fitView({ padding: 0.2 });
   }, 50);
 }, [nodes, edges, onNodesChange, fitView]);

 return (
   <>
     <div className="argument-map-container" ref={reactFlowWrapper}>
       <ReactFlow
         nodes={nodes}
         edges={edges}
         onNodesChange={onNodesChange}
         onEdgesChange={onEdgesChange}
         onConnect={onConnect}
         nodeTypes={memoizedNodeTypes}
         proOptions={{ hideAttribution: true }}
         maxZoom={2}
         minZoom={0.3}
         defaultViewport={{ x: 0, y: 0, zoom: 1 }}
       >
         <Controls
          showInteractive={false}
          showZoom={true}
          showFitView={true}
          showMiniMap={false}
         />
         <Background variant="dots" gap={12} size={1} />
       </ReactFlow>
       <ArgumentMenu />
       <div className="toggle-switch-container">
         <ToggleSwitch isDeductive={isDeductive} onChange={setIsDeductive} />
       </div>
       <EvaluateButtons onEvaluate={handleEvaluateWithFitView} onUntangle={handleUntangle} />
       <ClearButton
         type="chat"
         initialIcon={MessageCircleOff}
         confirmIcon={Trash2}
         onClear={() => useMessageStore.getState().clearMessages()}
         disabled={isLoading}
         data-tooltip="Clear your message log"
       />
       <ClearButton
         type="argument"
         initialIcon={RouteOff}
         confirmIcon={Trash2}
         onClear={() => {
           useArgumentStore.getState().setNodes([]);
           useArgumentStore.getState().setEdges([]);
         }}
         disabled={isLoading}
         data-tooltip="Clear your argument map"
       />
       <button 
         className="add-box-button" 
         onClick={addNewNode}
         disabled={isLoading}
       >
         <Plus size={60} />
       </button>
     </div>
     <DevSettings />
   </>
 );
});

const ArgumentMap = React.memo(() => {
 return (
   <ReactFlowProvider>
     <ArgumentMapContent />
   </ReactFlowProvider>
 );
});

export default ArgumentMap;
