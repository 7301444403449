import React, { useEffect, useState } from 'react';
import useDevStore from './stores/devStore';

const DevSettings = () => {
  const {
    isOpen,
    toggleOpen,
    prePrompt,
    firstPrompt,
    secondPrompt,
    model,
    advisorTemperature,
    chatTemperature,
    openSection,
    notification,
    setPrompt,
    setModel,
    setTemperature,
    setOpenSection,
    retrievePrompt,
    submitComment
  } = useDevStore();

  const [comment, setComment] = useState("");
  
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === 'd') {
        e.preventDefault();
        toggleOpen();
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [toggleOpen]);

  if (!isOpen) return null;

  const dropdownStyle = {
    position: 'fixed',
    top: '0',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: 'white',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    borderRadius: '0 0 8px 8px',
    padding: '1rem',
    zIndex: 1000,
    maxHeight: '80vh',
    overflowY: 'auto',
    width: '400px',
  };

  const sectionStyle = {
    marginBottom: '1rem',
  };

  const buttonStyle = {
    width: '100%',
    textAlign: 'left',
    padding: '0.5rem',
    backgroundColor: '#f0f0f0',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
  };

  const textareaStyle = {
    width: '100%',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    padding: '0.5rem',
    borderRadius: '4px',
    border: '1px solid #ddd',
  };

  const selectStyle = {
    width: '100%',
    marginTop: '0.5rem',
    padding: '0.5rem',
    borderRadius: '4px',
    border: '1px solid #ddd',
  };

  return (
    <div style={dropdownStyle}>
      {['pre', 'first', 'second'].map((promptType) => (
        <div key={promptType} style={sectionStyle}>
          <button 
            onClick={() => setOpenSection(openSection === `${promptType}-prompt` ? "" : `${promptType}-prompt`)}
            style={buttonStyle}
          >
            {promptType === 'pre' ? 'Message' : promptType.charAt(0).toUpperCase() + promptType.slice(1)} Prompt
          </button>
          {openSection === `${promptType}-prompt` && (
            <div>
              <textarea
                value={promptType === 'pre' ? prePrompt : promptType === 'first' ? firstPrompt : secondPrompt}
                onChange={(e) => setPrompt(promptType, e.target.value)}
                style={textareaStyle}
              />
              <button 
                onClick={() => retrievePrompt(promptType)}
                style={{...buttonStyle, backgroundColor: '#e0e0e0'}}
              >
                Retrieve {promptType === 'pre' ? 'Message' : promptType.charAt(0).toUpperCase() + promptType.slice(1)} Prompt
              </button>
            </div>
          )}
        </div>
      ))}

      <div style={sectionStyle}>
        <button 
          onClick={() => setOpenSection(openSection === 'model' ? "" : 'model')}
          style={buttonStyle}
        >
          Model
        </button>
        {openSection === 'model' && (
          <select
            value={model}
            onChange={(e) => setModel(e.target.value)}
            style={selectStyle}
          >
            <option value="GPT-4o">GPT-4o</option>
            <option value="o1-mini">o1-mini</option>
            <option value="Claude Sonnet">Claude Sonnet</option>
            <option value="Claude Opus">Claude Opus</option>
            <option value="nova-pro">Nova Pro</option>
            <option value="nova-micro">Nova Micro</option>
            <option value="nova-lite">Nova Lite</option>
          </select>
        )}
      </div>

      <div style={sectionStyle}>
        <button 
          onClick={() => setOpenSection(openSection === 'temperature' ? "" : 'temperature')}
          style={buttonStyle}
        >
          Temperatures
        </button>
        {openSection === 'temperature' && (
          <div style={{ marginTop: '0.5rem' }}>
            <div style={{ marginBottom: '0.5rem', display: 'flex', alignItems: 'center' }}>
              <label style={{ marginRight: '0.5rem' }}>Advisor:</label>
              <input
                type="number"
                min="0"
                max="10"
                step="0.1"
                value={advisorTemperature}
                onChange={(e) => setTemperature('advisor', parseFloat(e.target.value))}
                style={{ width: '60px', marginRight: '0.5rem', padding: '0.25rem' }}
              />
              <span>(0.0 - 10.0)</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label style={{ marginRight: '0.5rem' }}>Chat:</label>
              <input
                type="number"
                min="0"
                max="10"
                step="0.1"
                value={chatTemperature}
                onChange={(e) => setTemperature('chat', parseFloat(e.target.value))}
                style={{ width: '60px', marginRight: '0.5rem', padding: '0.25rem' }}
              />
              <span>(0.0 - 10.0)</span>
            </div>
          </div>
        )}
      </div>

      <div style={sectionStyle}>
        <button 
          onClick={() => setOpenSection(openSection === 'comment' ? "" : 'comment')}
          style={buttonStyle}
        >
          Comment
        </button>
        {openSection === 'comment' && (
          <div>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              style={textareaStyle}
            />
            <button 
              onClick={() => {
                submitComment('developer', comment).then(success => {
                  if (success) setComment("");
                });
              }}
              style={{...buttonStyle, backgroundColor: '#e0e0e0'}}
            >
              Submit Comment
            </button>
            {notification && (
              <div style={{ marginTop: '0.5rem', fontSize: '0.875rem', color: 'green' }}>
                {notification}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DevSettings;