export const validateArgumentStructure = (nodes, edges) => {
  // Build adjacency lists
  const outgoing = new Map();
  const incoming = new Map();

  nodes.forEach(node => {
    outgoing.set(node.id, []);
    incoming.set(node.id, []);
  });

  edges.forEach(edge => {
    outgoing.get(edge.source).push(edge.target);
    incoming.get(edge.target).push(edge.source);
  });

  // Find cycles using DFS
  const findCycles = () => {
    const cycles = [];
    const visited = new Set();
    const recursionStack = new Set();

    const dfs = (nodeId, path = []) => {
      if (recursionStack.has(nodeId)) {
        const cycleStart = path.indexOf(nodeId);
        cycles.push(path.slice(cycleStart));
        return;
      }

      if (visited.has(nodeId)) return;

      visited.add(nodeId);
      recursionStack.add(nodeId);
      path.push(nodeId);

      outgoing.get(nodeId).forEach(neighbor => {
        dfs(neighbor, [...path]);
      });

      recursionStack.delete(nodeId);
    };

    nodes.forEach(node => {
      if (!visited.has(node.id)) {
        dfs(node.id);
      }
    });

    return cycles;
  };

  // Find disconnected components
  const findExtraneous = () => {
    const reachable = new Set();
    const queue = [];

    // Start from premises (nodes with no incoming edges)
    nodes.forEach(node => {
      if (incoming.get(node.id).length === 0) {
        queue.push(node.id);
      }
    });

    // BFS to mark all reachable nodes
    while (queue.length > 0) {
      const current = queue.shift();
      if (reachable.has(current)) continue;

      reachable.add(current);
      outgoing.get(current).forEach(neighbor => {
        if (!reachable.has(neighbor)) {
          queue.push(neighbor);
        }
      });
    }

    return nodes.filter(node => !reachable.has(node.id));
  };

  // Find conclusions (nodes with no outgoing edges)
  const conclusions = nodes.filter(node =>
    outgoing.get(node.id).length === 0 &&
    incoming.get(node.id).length > 0
  );

  const cycles = findCycles();
  const extraneous = findExtraneous();
  const multipleConclusions = conclusions.length > 1 ? conclusions : [];

  const isValid = cycles.length === 0 &&
    conclusions.length === 1 &&
    extraneous.length === 0;

  return {
    cycles,
    extraneous,
    multipleConclusions,
    isValid
  };
};

export const layoutNodes = (nodes, edges) => {
  // Build adjacency & outdegree structures
  const adjacency = new Map();
  const outgoingEdges = new Map();
  const outdegree = new Map();
  const edgesByNodeId = new Map();

  for (const node of nodes) {
    adjacency.set(node.id, []);
    outgoingEdges.set(node.id, []);
    outdegree.set(node.id, 0);
    edgesByNodeId.set(node.id, []);
  }

  for (const edge of edges) {
    adjacency.get(edge.target).push({
      nodeId: edge.source,
      sourceHandle: edge.sourceHandle,
      targetHandle: edge.targetHandle
    });
    outgoingEdges.get(edge.source).push({
      nodeId: edge.target,
      sourceHandle: edge.sourceHandle,
      targetHandle: edge.targetHandle
    });
    outdegree.set(edge.source, outdegree.get(edge.source) + 1);
    edgesByNodeId.get(edge.source).push(edge);
    edgesByNodeId.get(edge.target).push(edge);
  }

  // Constants for layout
  const HORIZONTAL_SPACING = 250;
  const VERTICAL_SPACING = 250;
  const ITERATIONS = 30;

  // Node dimensions from CSS
  const NODE_WIDTH = 200;
  const NODE_PADDING = 15;
  const TEXTAREA_HEIGHT = 60;
  const HANDLE_SIZE = 12;
  const TOTAL_NODE_HEIGHT = TEXTAREA_HEIGHT + (2 * NODE_PADDING);

  // Helper to calculate handle offset
  const getHandleOffset = (node, handleId) => {
    const handle = node.data.handles.find(h => h.id === handleId);
    if (!handle) return { x: 0, y: 0 };
    
    const handlesInSamePosition = node.data.handles.filter(h => h.position === handle.position);
    const handleCount = handlesInSamePosition.length;
    
    const handleIndex = handlesInSamePosition
      .sort((a, b) => a.id.localeCompare(b.id))
      .findIndex(h => h.id === handle.id);
    
    const percentageOffset = ((handleIndex + 1) * 100) / (handleCount + 1);
    const xOffset = (percentageOffset / 100 * NODE_WIDTH) - (NODE_WIDTH / 2);
    const yOffset = (TOTAL_NODE_HEIGHT / 2) + (HANDLE_SIZE / 2);
    
    return {
      x: xOffset,
      y: handle.position === 'top' ? -yOffset : yOffset
    };
  };

  // Find conclusions
  const conclusions = nodes.filter(
    (node) => outgoingEdges.get(node.id).length === 0 && adjacency.get(node.id).length > 0
  );

  if (conclusions.length === 0 && nodes.length === 0) {
    return { positionChanges: [] };
  }

  const conclusion = conclusions.length > 0 ? conclusions[0] : nodes[0];

  // Build Level Map
  const levelMap = new Map();
  const queue = [{ nodeId: conclusion.id, level: 0 }];
  let head = 0;

  while (head < queue.length) {
    const { nodeId, level } = queue[head++];
    levelMap.set(nodeId, level);

    for (const neighbor of adjacency.get(nodeId)) {
      if (!levelMap.has(neighbor.nodeId)) {
        queue.push({ nodeId: neighbor.nodeId, level: level + 1 });
      }
    }
  }

  // Group nodes by level
  const levelNodes = new Map();
  let maxLevel = 0;

  nodes.forEach(node => {
    const level = levelMap.get(node.id) || 0;
    maxLevel = Math.max(maxLevel, level);
    if (!levelNodes.has(level)) {
      levelNodes.set(level, []);
    }
    levelNodes.get(level).push(node);
  });

  // Initial positioning
  const positions = new Map();
  positions.set(conclusion.id, { x: 0, y: 0 });

  // Helper to get weighted center considering handle positions
  const getConnectedCenter = (node, level) => {
    const incoming = adjacency.get(node.id);
    const outgoing = outgoingEdges.get(node.id);
    const connected = [...incoming, ...outgoing].filter(conn => positions.has(conn.nodeId));
    
    if (connected.length === 0) return 0;
    
    let sumX = 0;
    let totalWeight = 0;
    
    connected.forEach(conn => {
      const connectedNode = nodes.find(n => n.id === conn.nodeId);
      if (!connectedNode) return;

      const pos = positions.get(conn.nodeId);
      const sourceOffset = getHandleOffset(node, conn.sourceHandle || conn.targetHandle);
      const targetOffset = getHandleOffset(connectedNode, conn.targetHandle || conn.sourceHandle);
      
      // Consider both node position and handle offsets
      const effectiveX = pos.x + targetOffset.x;
      sumX += effectiveX;
      totalWeight += 1;
    });
    
    return totalWeight > 0 ? sumX / totalWeight : 0;
  };

  // Initial positioning by level
  for (let level = 1; level <= maxLevel; level++) {
    const nodesInLevel = levelNodes.get(level) || [];
    
    // Position based on connected nodes
    nodesInLevel.forEach(node => {
      const targetX = getConnectedCenter(node, level);
      positions.set(node.id, {
        x: targetX,
        y: -level * VERTICAL_SPACING
      });
    });

    // Resolve overlaps while maintaining relative positions
    const sortedNodes = [...nodesInLevel].sort((a, b) => 
      positions.get(a.id).x - positions.get(b.id).x
    );

    for (let i = 1; i < sortedNodes.length; i++) {
      const prev = positions.get(sortedNodes[i-1].id);
      const curr = positions.get(sortedNodes[i].id);
      if (curr.x - prev.x < HORIZONTAL_SPACING) {
        curr.x = prev.x + HORIZONTAL_SPACING;
      }
    }
  }

  // Iterative refinement considering handle positions
  for (let iter = 0; iter < ITERATIONS; iter++) {
    for (let level = maxLevel; level >= 0; level--) {
      const nodesInLevel = levelNodes.get(level) || [];
      
      // Calculate desired positions
      const desiredPositions = new Map();
      nodesInLevel.forEach(node => {
        const targetX = getConnectedCenter(node, level);
        desiredPositions.set(node.id, targetX);
      });

      // Sort nodes by position
      const sortedNodes = [...nodesInLevel].sort((a, b) => 
        positions.get(a.id).x - positions.get(b.id).x
      );

      // Position nodes while maintaining spacing
      sortedNodes.forEach((node, idx) => {
        const pos = positions.get(node.id);
        const desiredX = desiredPositions.get(node.id);
        
        const prevNode = sortedNodes[idx - 1];
        const nextNode = sortedNodes[idx + 1];
        
        let minX = -Infinity;
        let maxX = Infinity;
        
        if (prevNode) {
          minX = positions.get(prevNode.id).x + HORIZONTAL_SPACING;
        }
        
        if (nextNode) {
          maxX = positions.get(nextNode.id).x - HORIZONTAL_SPACING;
        }
        
        // Move towards desired position while respecting constraints
        const newX = Math.min(maxX, Math.max(minX, desiredX));
        positions.set(node.id, { x: newX, y: pos.y });
      });
    }
  }

  // Optimize positions to minimize maximum edge length between layers
  for (let level = maxLevel; level >= 1; level--) {
    const currentNodes = levelNodes.get(level) || [];
    const nodesBelow = levelNodes.get(level - 1) || [];
    
    if (currentNodes.length === 0 || nodesBelow.length === 0) continue;

    // For each node in current level, calculate its connected nodes in level below
    const connections = new Map();
    currentNodes.forEach(node => {
      const connectedBelow = edges
        .filter(edge => 
          (edge.source === node.id && nodesBelow.some(n => n.id === edge.target)) ||
          (edge.target === node.id && nodesBelow.some(n => n.id === edge.source))
        )
        .map(edge => {
          const sourceNode = nodes.find(n => n.id === edge.source);
          const targetNode = nodes.find(n => n.id === edge.target);
          const sourcePos = positions.get(edge.source);
          const targetPos = positions.get(edge.target);
          const sourceOffset = getHandleOffset(sourceNode, edge.sourceHandle);
          const targetOffset = getHandleOffset(targetNode, edge.targetHandle);
          
          return {
            edge,
            sourcePos: sourcePos.x + sourceOffset.x,
            targetPos: targetPos.x + targetOffset.x,
            length: Math.abs((sourcePos.x + sourceOffset.x) - (targetPos.x + targetOffset.x))
          };
        });
      
      connections.set(node.id, connectedBelow);
    });

    // Find the optimal offset that minimizes maximum edge length
    let bestOffset = 0;
    let minMaxLength = Infinity;
    
    // Try different offsets to minimize maximum edge length
    for (let offset = -HORIZONTAL_SPACING; offset <= HORIZONTAL_SPACING; offset += 10) {
      let maxLength = 0;
      
      connections.forEach((connectedEdges, nodeId) => {
        connectedEdges.forEach(conn => {
          const length = Math.abs((conn.sourcePos + offset) - conn.targetPos);
          maxLength = Math.max(maxLength, length);
        });
      });
      
      if (maxLength < minMaxLength) {
        minMaxLength = maxLength;
        bestOffset = offset;
      }
    }

    // Apply the best offset to all nodes in the current level
    currentNodes.forEach(node => {
      const pos = positions.get(node.id);
      positions.set(node.id, {
        x: pos.x + bestOffset,
        y: pos.y
      });
    });
  }

  // Final centering step for the entire layout
  let minX = Infinity;
  let maxX = -Infinity;
  
  nodes.forEach(node => {
    const pos = positions.get(node.id);
    minX = Math.min(minX, pos.x);
    maxX = Math.max(maxX, pos.x);
  });
  
  const centerOffset = -(maxX + minX) / 2;
  
  nodes.forEach(node => {
    const pos = positions.get(node.id);
    positions.set(node.id, {
      x: pos.x + centerOffset,
      y: pos.y
    });
  });

  // Build final position changes
  const positionChanges = nodes.map((node) => ({
    type: "position",
    id: node.id,
    position: positions.get(node.id) || { x: 0, y: 0 },
  }));

  return { positionChanges };
};