import React from 'react';
import { X, WandSparkles } from 'lucide-react';
import useArgumentStore from '../stores/argumentStore';

const EvaluateButtons = ({ onEvaluate, onUntangle }) => {
 const isLoading = useArgumentStore((state) => state.isLoading);
 const cancelEvaluation = useArgumentStore((state) => state.cancelEvaluation);

 return (
   <div className="evaluate-container">
     <button
       className="untangle-button"
       onClick={onUntangle}
       disabled={isLoading}
     >
       <WandSparkles size={20} />
     </button>
     
     <button
       className={`evaluate-button ${isLoading ? 'loading' : ''}`}
       onClick={onEvaluate}
       disabled={isLoading}
     >
       {isLoading ? <span className="loading-ellipsis"></span> : 'Evaluate'}
     </button>
     
     <button 
       className={`cancel-button ${isLoading ? 'active' : ''}`}
       onClick={cancelEvaluation}
       disabled={!isLoading}
     >
       <X />
     </button>
   </div>
 );
};

export default EvaluateButtons;