import { create } from 'zustand';
import useUserStore from './userStore';
import useMessageStore from './messageStore';
import useArgumentStore from './argumentStore';

const useSavedStore = create((set, get) => ({
  savedArguments: [],
  selectedArgumentId: null,
  isLoading: true,

  initializeFromStorage: async () => {
    const { isAuthenticated, addAuthHeader } = useUserStore.getState();
    
    if (!isAuthenticated) {
      const saved = localStorage.getItem('savedArguments');
      if (saved) {
        set({ savedArguments: JSON.parse(saved), isLoading: false });
      }
      return;
    }

    try {
      const response = await fetch('/api/archive', {
        headers: addAuthHeader(),
        credentials: 'include',
      });
      
      if (!response.ok) throw new Error('Failed to fetch archive');
      
      const data = await response.json();
      set({ savedArguments: data.savedArguments, isLoading: false });
    } catch (error) {
      console.error('Error fetching archive:', error);
      set({ isLoading: false });
    }
  },

  saveArgument: async (argumentData) => {
    const { isAuthenticated, addAuthHeader } = useUserStore.getState();
    const messages = useMessageStore.getState().messages;
    
    set((state) => {
      const newArgument = {
        id: Date.now(),
        name: `Argument ${state.savedArguments.length + 1}`,
        timestamp: new Date().toISOString(),
        data: {
          ...argumentData,
          messages
        }
      };
      
      const updatedArguments = [...state.savedArguments, newArgument];
      
      if (!isAuthenticated) {
        localStorage.setItem('savedArguments', JSON.stringify(updatedArguments));
        return { savedArguments: updatedArguments };
      }

      fetch('/api/archive', {
        method: 'PUT',
        headers: addAuthHeader({
          'Content-Type': 'application/json'
        }),
        credentials: 'include',
        body: JSON.stringify({ savedArguments: updatedArguments })
      }).catch(error => console.error('Error saving to archive:', error));
      
      return { savedArguments: updatedArguments };
    });
  },

  loadArgument: (argument) => {
    // Deep clone nodes and preserve handle structure
    const processedNodes = argument.data.nodes.map(node => ({
      ...node,
      data: {
        ...node.data,
        handles: [...node.data.handles]
      }
    }));

    // First set nodes with preserved handles
    useArgumentStore.setState({
      nodes: processedNodes,
      isDeductive: argument.data.isDeductive
    });

    // Wait for nodes to be fully initialized before setting edges
    setTimeout(() => {
      useArgumentStore.setState(state => ({
        ...state,
        edges: argument.data.edges
      }));

      // Force a refresh to ensure proper rendering
      setTimeout(() => {
        useArgumentStore.setState(state => ({...state}));
      }, 50);
    }, 100);

    // Load messages if they exist
    if (argument.data.messages) {
      useMessageStore.setState({ messages: argument.data.messages });
    } else {
      useMessageStore.getState().clearMessages();
    }
  },

  updateArgumentName: async (id, newName) => {
    const { isAuthenticated, addAuthHeader } = useUserStore.getState();
    
    set((state) => {
      const updatedArguments = state.savedArguments.map(arg => 
        arg.id === id ? { ...arg, name: newName } : arg
      );
      
      if (!isAuthenticated) {
        localStorage.setItem('savedArguments', JSON.stringify(updatedArguments));
        return { savedArguments: updatedArguments };
      }

      fetch('/api/archive', {
        method: 'PUT',
        headers: addAuthHeader({
          'Content-Type': 'application/json'
        }),
        credentials: 'include',
        body: JSON.stringify({ savedArguments: updatedArguments })
      }).catch(error => console.error('Error updating archive:', error));
      
      return { savedArguments: updatedArguments };
    });
  },

  deleteArgument: async (id) => {
    const { isAuthenticated, addAuthHeader } = useUserStore.getState();
    
    set((state) => {
      const updatedArguments = state.savedArguments.filter(arg => arg.id !== id);
      
      if (!isAuthenticated) {
        localStorage.setItem('savedArguments', JSON.stringify(updatedArguments));
        return {
          savedArguments: updatedArguments,
          selectedArgumentId: state.selectedArgumentId === id ? null : state.selectedArgumentId
        };
      }

      fetch('/api/archive', {
        method: 'PUT',
        headers: addAuthHeader({
          'Content-Type': 'application/json'
        }),
        credentials: 'include',
        body: JSON.stringify({ savedArguments: updatedArguments })
      }).catch(error => console.error('Error updating archive:', error));
      
      return {
        savedArguments: updatedArguments,
        selectedArgumentId: state.selectedArgumentId === id ? null : state.selectedArgumentId
      };
    });
  },

  setSelectedArgumentId: (id) => set({ selectedArgumentId: id })
}));

export default useSavedStore;