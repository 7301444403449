import { create } from 'zustand';
import { applyEdgeChanges, applyNodeChanges, addEdge } from '@xyflow/react';
import useMessageStore from './messageStore';
import useDevStore from './devStore';
import useUserStore from './userStore';
import { validateArgumentStructure, layoutNodes } from '../utils/graphUtils';
import { customAlphabet } from 'nanoid';

// Create a custom nanoid with visually distinct characters
const generateId = customAlphabet('23456789abcdefghkmnpqrstuvwxyz', 3);

// Create a Zustand store to manage global state
const useArgumentStore = create((set, get) => ({
  // Nodes and edges state
  nodes: [
    {
      id: '1',
      type: 'customNode',
      position: { x: 200, y: 100 },
      data: { 
        value: 'The land under Auckland is an active volcanic field.',
        handles: [
          {
            id: 'top-a',
            type: 'independent',
            position: 'top'
          },
          {
            id: 'bottom-a',
            type: 'independent',
            position: 'bottom'
          }
        ],
      },
      draggable: true,
    },
    {
      id: '2',
      type: 'customNode',
      position: { x: 500, y: 100 },
      data: { 
        value: 'Any active volcanic field might erupt into a new volcano.',
        handles: [
          {
            id: 'top-a',
            type: 'independent',
            position: 'top'
          },
          {
            id: 'bottom-a',
            type: 'independent',
            position: 'bottom'
          }
        ],
      },
      draggable: true,
    },
    {
      id: '3',
      type: 'customNode',
      position: { x: 350, y: 300 },
      data: { 
        value: 'The land under Auckland might erupt into a new volcano.',
        handles: [
          {
            id: 'top-a',
            type: 'dependent',
            position: 'top'
          },
          {
            id: 'bottom-a',
            type: 'independent',
            position: 'bottom'
          }
        ],
      },
      draggable: true,
    },
  ],
  edges: [
    {
      id: 'e1-3',
      source: '1',
      target: '3',
      sourceHandle: 'bottom-a',
      targetHandle: 'top-a',
    },
    {
      id: 'e2-3',
      source: '2',
      target: '3',
      sourceHandle: 'bottom-a',
      targetHandle: 'top-a',
    },
  ],

  // Deductive mode state
  isDeductive: true,

  // Loading state for evaluation
  isLoading: false,

  // Previous structure and message (for evaluation context)
  previousStructure: '',
  previousMessage: '',

  // Action to set nodes
  setNodes: (nodes) => set({ nodes }),

  // Action to set edges
  setEdges: (edges) => set({ edges }),

  // Handle node changes (e.g., position, selection)
  onNodesChange: (changes) =>
    set((state) => {
      // First apply the changes
      const updatedNodes = applyNodeChanges(changes, state.nodes)
      // Then deduplicate by ID before setting state
      const uniqueNodes = updatedNodes.filter((node, index, self) => 
        index === self.findIndex(n => n.id === node.id)
      );
      return { nodes: uniqueNodes };
    }),

  // Handle edge changes (e.g., deletion)
  onEdgesChange: (changes) =>
    set((state) => ({
      edges: applyEdgeChanges(changes, state.edges),
    })),

  // Helper to check if a handle has connections
  hasConnections: (nodeId, handleId) => {
    const state = get();
    return state.edges.some(
      edge => 
        (edge.source === nodeId && edge.sourceHandle === handleId) ||
        (edge.target === nodeId && edge.targetHandle === handleId)
    );
  },

  // Handle new connections between nodes
  onConnect: (connection) =>
    set((state) => {
      // Check if this is a source handle (bottom handle)
      const sourceNode = state.nodes.find(n => n.id === connection.source);
      const sourceHandle = sourceNode?.data.handles.find(h => h.id === connection.sourceHandle);
      
      // If it's a bottom handle and already has a connection, prevent the new connection
      if (sourceHandle?.position === 'bottom' && 
          state.edges.some(e => 
            e.source === connection.source && 
            e.sourceHandle === connection.sourceHandle
          )) {
        return state;
      }
      
      return {
        edges: addEdge(connection, state.edges),
      };
    }),

  // Add a new node to the map
  addNewNode: () =>
    set((state) => ({
      nodes: [
        ...state.nodes,
        {
          id: generateId(),
          type: 'customNode',
          position: { x: Math.random() * 500, y: Math.random() * 300 },
          data: {
            value: ``,
            handles: [
              {
                id: 'top-a',    // Keep the existing handle ID pattern
                type: 'independent',
                position: 'top'
              },
              {
                id: 'bottom-a', // Keep the existing handle ID pattern
                type: 'independent',
                position: 'bottom'
              }
            ],
          },
          draggable: true,
        },
      ],
    })),

  // Update node data (e.g., text content)
  updateNodeData: (nodeId, newData) =>
    set((state) => ({
      nodes: state.nodes.map((node) =>
        node.id === nodeId ? { ...node, data: { ...node.data, ...newData } } : node
      ),
    })),

  // Update node handles
  updateNodeHandles: (nodeId, newHandles) =>
    set((state) => {
      const updatedNodes = state.nodes.map((node) =>
        node.id === nodeId ? { ...node, data: { ...node.data, handles: newHandles } } : node
      );
  
      // Get all valid handle IDs from all nodes
      const validHandleIds = updatedNodes.flatMap(node => 
        node.data.handles.map(handle => handle.id)
      );
  
      // Only filter edges connected to the modified node
      const updatedEdges = state.edges.filter(edge => {
        if (edge.source === nodeId) {
          return validHandleIds.includes(edge.sourceHandle);
        }
        if (edge.target === nodeId) {
          return validHandleIds.includes(edge.targetHandle);
        }
        return true;
      });
  
      return {
        nodes: updatedNodes,
        edges: updatedEdges,
      };
    }),

  // Toggle deductive mode
  setIsDeductive: () => set((state) => ({ isDeductive: !state.isDeductive })),

  // Set loading state
  setIsLoading: (isLoading) => set({ isLoading }),

  // Add to state in src/stores/argumentStore.js
  abortController: null,

  // Add to actions in argumentStore.js
  cancelEvaluation: () => {
    const { abortController } = get();
    if (abortController) {
      abortController.abort();
      set({ 
        isLoading: false, 
        abortController: null 
      });
    }
  },

  handleEvaluate: async () => {
    console.log('Starting evaluation...');
    let {
      nodes,
      edges,
      isDeductive,
      previousStructure,
      previousMessage,
    } = get();

    // Get user role
    const { role } = useUserStore.getState();

    // Validate argument structure first
    const validation = validateArgumentStructure(nodes, edges);
    if (!validation.isValid) {
      // Convert node IDs to readable text for warnings
      const nodeLookup = new Map(nodes.map(n => [n.id, n.data.value]));
      
      let errorMessage = 'Cannot evaluate argument due to structural issues:\n';
      
      if (validation.cycles.length > 0) {
        errorMessage += ' circular arguments detected.\n\n';
        validation.cycles.forEach(cycle => {
          const readableCycle = cycle.map(id => nodeLookup.get(id)).join(' → ');
          errorMessage += `  ${readableCycle} → ${nodeLookup.get(cycle[0])}\n`;
        });
      }
      
      if (validation.extraneous.length > 0) {
        errorMessage += ' disconnected components found.\n\n';
        validation.extraneous.forEach(node => {
          errorMessage += `  "${node.data.value}" is not connected to the main argument\n`;
        });
      }

      if (validation.multipleConclusions.length > 0) {
        errorMessage += ' multiple conclusions detected.\n\n';
        validation.multipleConclusions.forEach(node => {
          errorMessage += `  "${node.data.value}" appears to be a conclusion\n\n`;
        });
        errorMessage += '  An argument should have exactly one final conclusion.\n';
      }

      useMessageStore.getState().addMessage(errorMessage, 'bot');
      return;
    }

    // If valid, layout the nodes 
    try {
      // layoutNodes returns { positionChanges, boundingBox }
      const { positionChanges, boundingBox } = layoutNodes(nodes, edges);

      // Update node positions in React Flow
      useArgumentStore.getState().onNodesChange(positionChanges);
      // Force immediate re-render
      set((state) => ({ ...state }));
      // Let React Flow render the updated positions
      await new Promise((resolve) => setTimeout(resolve, 50));

    } catch (error) {
      console.error('Layout error:', error);
    }

    // Get settings from devStore
    const { prePrompt, firstPrompt, secondPrompt, model, temperature } = useDevStore.getState();
    const { addAuthHeader } = useUserStore.getState();

    try {
      // Use the layouted nodes for formatting
      const formattedArgumentStructure = {
        type: isDeductive ? 'deductive' : 'non-deductive',
        boxes: nodes.map((node) => {
          const nodeEdges = edges.filter(
            (edge) => edge.source === node.id || edge.target === node.id
          );

          const getConnectedBoxes = (handleId) => {
            return nodeEdges
              .filter(
                (edge) =>
                  edge.sourceHandle === handleId || edge.targetHandle === handleId
              )
              .map((edge) => (edge.source === node.id ? edge.target : edge.source));
          };

          return {
            id: node.id,
            text: node.data.value,
            handles: {
              top: (node.data.handles || [])
                .filter((h) => h.position === 'top')
                .map((h) => ({
                  connectionID: h.id,
                  connectionType: h.type || 'independent',
                  connectedBoxes: getConnectedBoxes(h.id),
                })),
              bottom: (node.data.handles || [])
                .filter((h) => h.position === 'bottom')
                .map((h) => ({
                  connectionID: h.id,
                  connectionType: h.type || 'independent',
                  connectedBoxes: getConnectedBoxes(h.id),
                })),
            },
          };
        }),
        connections: edges.map((edge) => ({
          from: edge.source,
          to: edge.target,
          sourceHandle: edge.sourceHandle,
          targetHandle: edge.targetHandle,
        })),
      };

      const payload = {
        argumentStructure: formattedArgumentStructure,
        previousStructure,
        previousMessage,
        argumentType: isDeductive ? 'deductive' : 'non-deductive',
        parserPrompt: prePrompt,
        evaluatorPrompt: firstPrompt,
        advisorPrompt: secondPrompt,
        model,
        temperature,
        role: role,
      };

      // console.log('Sending evaluation payload:', payload);

      // Create new AbortController
      const controller = new AbortController();
      set({ 
        abortController: controller,
        isLoading: true 
      });

      const response = await fetch('/api/map', {
        method: 'POST',
        headers: addAuthHeader({
          'Content-Type': 'application/json'
        }),
        credentials: 'include',
        body: JSON.stringify(payload),
        signal: controller.signal  // Add this line
      });

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error('Authentication required');
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // console.log('Received evaluation response:', data);

      // Only update state if not cancelled
      if (get().abortController === controller) {
        set({
          previousStructure: data.parsedArgument,
          previousMessage: data.tutorAdvice,
        });
        
        useMessageStore.getState().addMessage(data.tutorAdvice, 'bot');
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Evaluation cancelled');
        return;
      }
      console.error('Evaluation error:', error);
      useMessageStore.getState().addMessage(
        'An error occurred while evaluating the argument: ' + error.message,
        'bot'
      );

      if (error.message === 'Authentication required') {
        useUserStore.getState().logout();
      }
    } finally {
      set({ 
        isLoading: false,
        abortController: null 
      });
    }
  },
}));

export default useArgumentStore;
