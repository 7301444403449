import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import useMessageStore from './stores/messageStore';
import useArgumentStore from './stores/argumentStore';
import useDevStore from './stores/devStore';
import useUserStore from './stores/userStore';  

const ChatBox = () => {
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  
  // Get messages and actions from stores
  const messages = useMessageStore(state => state.messages);
  const addMessage = useMessageStore(state => state.addMessage);
  
  // Get argument structure from argument store
  const nodes = useArgumentStore(state => state.nodes);
  const edges = useArgumentStore(state => state.edges);
  const isDeductive = useArgumentStore(state => state.isDeductive);

  // Get dev store settings individually
  const model = useDevStore(state => state.model);
  const prePrompt = useDevStore(state => state.prePrompt);
  const firstPrompt = useDevStore(state => state.firstPrompt);
  const secondPrompt = useDevStore(state => state.secondPrompt);
  const temperature = useDevStore(state => state.temperature);

  // Get auth helper
  const addAuthHeader = useUserStore(state => state.addAuthHeader);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (inputMessage.trim()) {
      setIsLoading(true);
      
      addMessage(inputMessage, 'user');
      
      try {
        const argumentStructure = {
          type: isDeductive ? 'deductive' : 'non-deductive',
          boxes: nodes.map(node => ({
            id: node.id,
            text: node.data.value,
            handles: node.data.handles
          })),
          connections: edges.map(edge => ({
            from: edge.source,
            to: edge.target,
            sourceHandle: edge.sourceHandle,
            targetHandle: edge.targetHandle,
          }))
        };

        const chatHistory = messages
          .map(msg => `${msg.type === 'user' ? 'User' : 'Assistant'}: ${msg.text}`)
          .join('\n');

        const { role } = useUserStore.getState();

        const response = await fetch('/api/message', {
          method: 'POST',
          headers: addAuthHeader({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            message: inputMessage,
            argumentStructure,
            chatHistory,
            devSettings: {
              model,
              prePrompt,
              firstPrompt,
              secondPrompt,
              temperature
            },
            role
          }),
        });

        if (!response.ok) {
          if (response.status === 401) {
            // Handle unauthorized error - could redirect to login or show message
            throw new Error('Authentication required. Please log in again.');
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        addMessage(data.message, 'bot');
      } catch (error) {
        console.error('Error sending message:', error);
        addMessage(`Error: ${error.message}`, 'bot');
        
        // If unauthorized, trigger logout
        if (error.message.includes('Authentication required')) {
          useUserStore.getState().logout();
        }
      } finally {
        setIsLoading(false);
        setInputMessage('');
      }
    }
  }, [inputMessage, addMessage, nodes, edges, messages, isDeductive, 
      model, prePrompt, firstPrompt, secondPrompt, temperature, addAuthHeader]);

  // Rest of the component remains the same...
  const messagesList = useMemo(() => (
    messages.map((msg) => (
      <div 
        key={msg.id} 
        className={`message ${msg.type} ${
          msg.type === 'evaluation' ? 'whitespace-pre-wrap border-l-4 border-purple-500 bg-purple-50' : ''
        }`}
      >
        {msg.type === 'evaluation' && (
          <div className="font-semibold text-purple-700 mb-1">Argument Evaluation:</div>
        )}
        <ReactMarkdown 
          children={msg.text}
          components={{
            code: ({node, inline, className, children, ...props}) => {
              const match = /language-(\w+)/.exec(className || '');
              return !inline ? (
                <pre className="bg-gray-100 p-2 rounded">
                  <code className={className} {...props}>
                    {children}
                  </code>
                </pre>
              ) : (
                <code className="bg-gray-100 px-1 rounded" {...props}>
                  {children}
                </code>
              );
            },
            h1: ({node, ...props}) => <h1 className="text-2xl font-bold my-2" {...props} />,
            h2: ({node, ...props}) => <h2 className="text-xl font-bold my-2" {...props} />,
            h3: ({node, ...props}) => <h3 className="text-lg font-bold my-2" {...props} />,
            ul: ({node, ...props}) => <ul className="list-disc ml-4 my-2" {...props} />,
            ol: ({node, ...props}) => <ol className="list-decimal ml-4 my-2" {...props} />,
          }}
        />
      </div>
    ))
  ), [messages]);

  return (
    <div className="chat-box">
      <div className="messages">
        {messagesList}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={handleSubmit} className="input-form">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Type your question here..."
          disabled={isLoading}
          className="flex-1 p-2 border rounded"
        />
        <button 
          type="submit" 
          disabled={isLoading}
          className={`${isLoading ? 'loading loading-ellipsis' : ''}`}
        >
          {isLoading ? '' : 'Send'}
        </button>
      </form>
    </div>
  );
};

export default ChatBox;