import { Link } from 'react-router-dom';

export default function About() {
  return (
    <div className="legal-page">
      <div className="legal-content">
        <h1>About</h1>
        
        <section>
          <p>Critical thinking is the foundation of active reading, clear writing, and effective career decision-making. What can we do to improve high school and university students' critical thinking skills?</p>
          
          <p>This is Diagram Academy's mission. Our reasoning app provides rapid feedback on student-created argument diagrams, turning abstract reasoning into a visual, interactive experience. This helps students:</p>
          <ul>
            <li>strengthen their own arguments by clarifying their reasoning process,</li>
            <li>compare their reasoning process with their peers', and</li>
            <li>identify flawed reasoning that they encounter in the media.</li>
          </ul>
          
          <p>Our app is inspired by <a href="#research">the research listed at the bottom of this page</a>. While no AI-powered app is perfect, we believe that ours is the most effective one for boosting skills in reasoning, and we look forward to showing you why. Contact us at <a href="mailto:support@nscribe.ai">support@nscribe.ai</a>, and one of the co-founders will set up your free demo.</p>
        </section>

        <section>
          <h2>What do students do?</h2>
          
          <p>Diagramming arguments ensures that students think explicitly about their reasoning. These diagrams represent the reasoning process by showing different types of connections between what each student believes (their conclusion) and the reasons why they believe it (their premises). Premises can themselves be sub-conclusions which are justified by other premises, producing a tree structure. The main conclusion is like the trunk, and the argument stretches upwards through sub-conclusions (branches) to the premises (leaves).</p>
          
          <p>The process:</p>
          <ol>
            <li>Students use their account on the app to diagram
              <ul>
                <li>their own argument,</li>
                <li>an argument they find in the media, or</li>
                <li>an argument generated by the app.</li>
              </ul>
            </li>
            <li>The app rapidly evaluates whether students have structured their ideas logically and avoided hidden assumptions.</li>
            <li>The app advises students on how they can strengthen their argument. Instructors can control how much help it gives.</li>
            <li>Students can then ask the app for more advice, strengthen their argument, and get a new evaluation of their strengthened argument.</li>
          </ol>
          
          <p>After that, they move on to diagramming a more complex, ambitious argument.</p>
          
          <p>Our app can also be used to:</p>
          <ul>
            <li>prepare students for a presentation or debate through collaboration on one large diagram;</li>
            <li>offer assessments:
              <ul>
                <li>each student makes one or a few argument diagrams, without AI, during a timed assessment in class (or at home);</li>
                <li>instructors use the AI for tips, speeding up their assessment of each student's work;</li>
              </ul>
            </li>
            <li>let each student outline the essays they are about to write in a more intuitive form than indented bullet points allow.</li>
          </ul>
        </section>

        <section>
          <h2>Why use an app?</h2>
          <ul>
            <li>Students can get instant feedback and continue working, rather than waiting for instructor feedback.</li>
            <li>Even though the research listed below shows that extensive practice diagramming arguments is the most effective teaching method for critical thinking, hiring enough teaching assistants to review a lot of diagrams per student is financially unrealistic.</li>
            <li>Mastery learning requires individual pacing. This is easier for instructors to implement when our app can customize assignments to challenge each student at the right level.</li>
          </ul>
        </section>

        <section>
          <h2>Who are we?</h2>
          <p>Our curriculum is guided by Tristan Heine, a doctoral student working on Kantian epistemology in the Department of Philosophy at the University of Auckland in New Zealand. Tristan helped teach several semesters of Philosophy 105: Critical Thinking, the university's most popular general education course, which is currently testing our app.</p>
          
          <p>Tristan spent years as a full-time teacher of English and science in several primary and secondary schools in New Zealand and Australia. He developed this app along with Mark Eaglesfield, whom he taught as an undergraduate in Philosophy 105: Critical Thinking, and technical support from Australia-based nScribe, which also handles billing for Diagram Academy.</p>
          
          <p>For a free demo, please contact us at <a href="mailto:support@nscribe.ai">support@nscribe.ai</a>, and one of the co-founders will respond. We can provide in-person demos in Auckland.</p>
        </section>

        <section>
          <h2 id="research">What research has been done on argument diagrams and mastery learning?</h2>
          <p>Our app draws inspiration from two main areas of scholarly research on effective teaching strategies:</p>
          <ul>
            <li>critical thinking, which shows that the most effective teaching method is large amounts of practice diagramming arguments; and</li>
            <li>mastery learning, which shows that students benefit from spending differing amounts of time to master concepts before moving to harder concepts.</li>
          </ul>

          <p>There are dozens of relevant studies. Here is a short selection:</p>
          <ul>
            <li>Álvarez Ortiz, C.M., 2007. Does philosophy improve critical thinking skills? Thesis, University of Melbourne.</li>
            <li>Dwyer, C., et al., 2012. An evaluation of argument mapping as a method of enhancing critical thinking performance in e-learning environments. Metacognition Learning 7:219-244.</li>
            <li>Harrell, M., 2012. Assessing the efficacy of argument diagramming to teach critical thinking skills in Introduction to Philosophy. Inquiry: Critical Thinking Across the Disciplines 27 (2):31-39.</li>
            <li>Kulik, C., et al., 1990. Effectiveness of mastery learning programs: A meta-analysis. Review of Educational Research 60(2): 265-299.</li>
            <li>Twardy, C., 2004. Argument maps improve critical thinking. Teaching Philosophy 27 (2):95-116 (2004).</li>
            <li>van Gelder, T., 2015. Using argument mapping to improve critical thinking skills. In: M. Davies & R. Barnett (eds.), The Palgrave Handbook of Critical Thinking in Higher Education.</li>
          </ul>
        </section>

        <div className="legal-nav">
          <Link to="/" className="back-link">Back to Login</Link>
        </div>
      </div>
    </div>
  );
}