import { create } from 'zustand';

const useUserStore = create((set, get) => ({
  user: null,
  isAuthenticated: false,
  role: null,
  token: null,
  isLoading: true,

  addAuthHeader: (headers = {}) => {
    const { token } = get();
    return token ? { ...headers, 'Authorization': `Bearer ${token}` } : headers;
  },

  isAdmin: () => {
    return get().role === 'admin';
  },

  login: async (username, password) => {
    try {
      const response = await fetch('/api/auth/login', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        throw new Error('Invalid credentials');
      }

      const data = await response.json();
      
      set({
        user: username,
        isAuthenticated: true,
        role: data.role,
        token: data.token,
        isLoading: false
      });

      return true;
    } catch (error) {
      console.error('Login error:', error);
      set({ isLoading: false });
      return false;
    }
  },

  loginWithGoogle: async (credential) => {
    try {
      const response = await fetch('/api/auth/google', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ credential }),
      });

      if (!response.ok) {
        throw new Error('Google login failed');
      }

      const data = await response.json();
      
      set({
        user: data.user.email,
        isAuthenticated: true,
        role: 'student',
        token: data.token,
        isLoading: false
      });

      return true;
    } catch (error) {
      console.error('Google login error:', error);
      set({ isLoading: false });
      return false;
    }
  },

  logout: async () => {
    try {
      // Call server logout endpoint to clear cookies
      await fetch('/api/auth/logout', {
        method: 'POST',
        credentials: 'include'
      });
      
      // Clear local state
      set({
        user: null,
        isAuthenticated: false,
        role: null,
        token: null
      });
      
      window.location.reload();
    } catch (error) {
      console.error('Logout error:', error);
      // Still clear local state even if server request fails
      set({
        user: null,
        isAuthenticated: false,
        role: null,
        token: null
      });
      window.location.reload();
    }
  },

  checkAuth: async () => {
    try {
      
      // Always try verification - httpOnly cookie will be sent automatically
      const response = await fetch('/api/auth/verify', {
        method: 'POST',
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Not authenticated');
      }

      const data = await response.json();
      
      // Repopulate state with verified data
      set({
        user: data.username || data.user?.email,
        isAuthenticated: true,
        role: data.role,
        token: data.token, // Server should send fresh token
        isLoading: false
      });
      
      return true;
    } catch (error) {
      set({
        user: null,
        isAuthenticated: false,
        role: null,
        token: null,
        isLoading: false
      });
      return false;
    }
  }
}));

export default useUserStore;